import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProcessSteps from '../../../../components/ProcessSteps';
import OneProduct__TYMentionDC from './OneProduct__TYMentionDC__s1';
import OneProduct__TYMentionDC__s2 from './OneProduct__TYMentionDC__s2';

import OneProduct__AutomatedFormat from './OneProduct__AutomatedFormat';
import OneProduct__PricingMethod from './OneProduct__PricingMethod';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%,0)',
    width: 'max-content',
    textAlign: 'center',
    fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

export default function OneProductMarketplaceSteps__mobile(props) {
  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const stepsNames = [
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{
        color: index === 0 ? 'white' : '909090',
        // width: '35px',
        position: 'absolute',
        top: '-1px',
        left: '43px',
        lineHeight: 1.2,
      }}
    >
      Thank you <br />
      Mention <br />
      digital card
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{
        color: index === 1 ? 'white' : '909090',
        position: 'absolute',
        top: '-54px',
        left: '-8px',
        lineHeight: 1.2,
      }}
    >
      Automated <br />
      Format &<br /> Delivery
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{
        color: index === 2 ? 'white' : '909090',
        position: 'absolute',
        top: '-104px',
        left: '-70px',
        lineHeight: 1.2,
      }}
    >
      Pricing Method
    </Typography>,
  ];

  const [swipIndex, setSwipIndex] = useState(0);

  const handleChangeIndex = (i) => {
    setSwipIndex(i);
    console.log(i);
  };
  return (
    <SwipeableViews
      disabled={index !== 0}
      index={swipIndex}
      onChangeIndex={handleChangeIndex}
      style={{ margin: 0 }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 'max-content',
          width: '100vw',
          padding: 0,
          margin: 0,
          marginBottom: 5,
        }}
      >
        <div style={{ zIndex: 5, margin: ' 0', width: 'fit-content' }}>
          <ProcessSteps
            index={index}
            setIndex={setIndex}
            length={stepsNames.length}
            stepsNames={stepsNames}
          />
        </div>

        <Box sx={{}}>
          {index === 0 && (
            <OneProduct__TYMentionDC
              fontStyle={classes.textMonarcha}
              setSwipIndex={handleChangeIndex}
            />
          )}
          {index === 1 && <OneProduct__AutomatedFormat fontStyle={classes.textMonarcha} />}
          {index === 2 && <OneProduct__PricingMethod fontStyle={classes.textMonarcha} />}
        </Box>
      </Box>
      
      <OneProduct__TYMentionDC__s2
        fontStyle={classes.textMonarcha}
        setSwipIndex={handleChangeIndex}
      />
    </SwipeableViews>
  );
}
