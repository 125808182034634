import { Box, Typography } from '@mui/material';
import DigitalCard from '../../../../../../../images/digital-card.png';
import DoubleArrows from '../../../../../../../images/double-arrow-down-white.png';

export default function OneProduct__TYMentionDC__s1(props) {
  return (
    <Box>
      <Typography
        className={props.fontStyle}
        sx={{
          position: 'relative',
          display: 'block',
          textAlign: 'center',
          color: '#ffffff44',
          lineHeight: 1.2,
          marginTop: 15,
          fontSize: '11px',
        }}
      >
        On PassionStocks,
        <br />
        <span style={{ color: '#ffbf12' }}>
          creators sell only one product:
          <br /> a thank-you mention card.
        </span>
        <br />
        <br />
        <span style={{ color: '#ffbf12' }}>
          Buyers get a one-time Instagram story mention per card
        </span>
        <br />
        and, if they hold the card,
        <br /> an annual end-of-year mention <br />
        as a courtesy.
      </Typography>
      <br />
      <br />
      <Typography
        className={props.fontStyle}
        sx={{
          color: '#ffffff66',
          textAlign: 'center',
          fontSize: '12px',
          lineHeight: 1.2,
          marginBottom: 1,
        }}
      >
        This is a thank-you mention
        <br /> digital card (marketplace view)
      </Typography>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={DigitalCard}
          alt="Thank You Mention Digital Card"
          style={{ width: '55vw', paddingBottom: '10px' }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '9vw',
            opacity: 0.44,
            cursor: 'pointer',
          }}
          onClick={() => {
            props.setSwipIndex(1);
          }}
        >
          <img
            src={DoubleArrows}
            alt="Click to view card details"
            style={{ width: '18px', transform: 'rotate(-90deg)' }}
          />
          <Typography
            className={props.fontStyle}
            sx={{ fontSize: '12px', textAlign: 'center', lineHeight: 1.2, marginTop: 0.5 }}
          >
            1/2
          </Typography>
        </div>
      </div>
    </Box>
  );
}
