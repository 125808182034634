import { Container, Typography } from '@mui/material';

import TreeChartImg from '../../../../images/tree-chart.png';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { withWidth } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%,0)',
    width: 'max-content',
    textAlign: 'center',
    fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

const TreeChart = ({ width, ...props }) => {
  const classes = useStyles();

  return (
    <Container sx={{ height: 'fit-content', marginBottom: 0 }}>
      <Typography
        className={classes.textMonarcha}
        variant="h5"
        sx={{
          textAlign: 'center',
          lineHeight: 1.3,
          color: '#ffffff77',
          marginBottom: { xs: 1, sm: 0 },
        }}
      >
        Think of your personal brand
        <br /> like a tree
      </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{
          textAlign: 'center',
          lineHeight: 1.3,
          color: '#ffffff77',
          marginBottom: { xs: 2, sm: 0 },
        }}
      >
        monetize your brand for today, grow its value for tomorrow{' '}
      </Typography>
      <img
        src={TreeChartImg}
        alt="Tree chart"
        style={{
          display: 'block',
          margin: width === 'xs' ? '20px auto' : '20px auto 35px',
          width: width === 'xs' ? '50vw' : '35vw',
          maxWidth: '370px',
        }}
      />
      <Typography
        className={classes.textMonarcha}
        variant="subtitle1"
        sx={{
          color: '#ffffff44',
          lineHeight: 1.3,
          textAlign: 'center',
          marginTop: 2,
          marginBottom: 4,
          fontSize: { xs: '11px', sm: '' },
        }}
      >
        This <span style={{ color: '#ffbf12' }}>tree</span> symbolizes your{' '}
        <span style={{ color: '#ffbf12' }}>personal brand asset</span>, <br />
        and the <span style={{ color: '#ffbf12' }}>fruit</span> symbolize {width == 'xs' && <br />}
        <span style={{ color: '#ffbf12' }}>personal brand monetization</span>. <br />
        <br />
        With PassionStocks, <br />
        <span style={{ color: '#ffbf12' }}>each</span> thank-you mention card{' '}
        <span style={{ color: '#ffbf12' }}>sale</span>
        {width == 'xs' && <br />} provides immediate <br />
        <span style={{ color: '#ffbf12' }}>earnings & adding tangible value</span> <br />
        to your <span style={{ color: '#ffbf12' }}>personal brand asset</span> by{' '}
        {width == 'xs' && <br />}
        <span style={{ color: '#ffbf12' }}>showcasing</span> its{' '}
        <span style={{ color: '#ffbf12' }}>potential</span>. <br />
        <br />
        Similar to how a fruitful tree showcases its potential and increases its value, <br />
        <span style={{ color: '#ffbf12' }}>
          each sale boosts the worth of{width == 'xs' && <br />} your personal brand asset
        </span>{' '}
        <br />
        through successful monetization.
      </Typography>
    </Container>
  );
};

export default withWidth()(TreeChart);
