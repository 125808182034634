import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';

export default function CommunityImpact__EarningFromGrowth(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          <span style={{ color: '#ffbf12' }}>
            Cardholders benefit from your increasing popularity <br />
            exactly as you do. <br /> <br />
            As your fame grows, you can charge more for your products,
          </span>{' '}
          <br />
          and{' '}
          <span style={{ color: '#ffbf12' }}>
            cardholders can resell their Thank You Mention cards at higher prices
          </span>
          .
          <br /> <br />
          Because the <span style={{ color: '#ffbf12' }}>cards are re-consumable</span>, <br />
          the{' '}
          <span style={{ color: '#ffbf12' }}>
            new buyer receives the same one-time story mention
          </span>
          , <br />
          maintaining the card’s full value.
          <br /> <br />
          If cardholders choose to hold onto their cards, <br />
          they will receive an annual end-of-year thank-you mention as a courtesy.
          <br /> <br />
          This partnership allows your community to profit from your success, <br />
          <span style={{ color: '#ffbf12' }}>
            turning followers into engaged business partners who grow with you.
          </span>
        </Typography>
      </Container>
    </FadeIn>
  );
}
