import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './store/store.js';
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StylesProvider, jssPreset, createGenerateClassName } from '@mui/styles';
import { create } from 'jss';

import store from './store/store.js';
import './styles/app-style.css';
import './styles/XD_home.css';
import './style.css';

import Root from './pages/root/index.jsx';
// import LandingPage from './pages/landing-page/creators-slides/index.jsx';
import GeneralError from './pages/errors/general/index.jsx';
import TsAndCs from './pages/ts-and-cs/index.jsx';
import CookiePolicy from './pages/cookie-policy/index.jsx';
import PrivacyPolicy from './pages/privacy-policy/index.jsx';
import LandingPageLayout from './pages/landing-page/index.jsx';
import theme from './styles/theme.js';

window.addEventListener(
  'resize',
  function (event) {
    store.dispatch({
      type: 'ui/windowInnerSize/add',
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  },
  true,
);

// const theme = createTheme();

// Configure JSS
const generateClassName = createGenerateClassName({
  productionPrefix: 'PS-', // Customize this prefix to avoid collisions
});

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<GeneralError />}>
      <Route path="/" element={<LandingPageLayout />} />
      <Route path="terms-and-conditions" element={<TsAndCs />} />
      <Route path="cookie-policy" element={<CookiePolicy />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
    </Route>,
  ),
  {},
);
const root = ReactDOM.createRoot(document.getElementById('root1'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss} generateClassName={generateClassName}>
            <RouterProvider router={router} />
          </StylesProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
