import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../layouts/fade-in';
import BrandSynergy from '../../../../../../images/brand-synergy.svg';

export default function LimitedAutomatedTYM__BrandSynergy(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          To achieve a $1B personal brand, <br />
          adopt a <span style={{ color: '#ffbf12' }}>scalable business model</span> similar to
          successful <span style={{ color: '#ffbf12' }}>startups</span>.
          <br />
          <br />
          Selling thank-you mention cards on PassionStocks <br />
          helps <span style={{ color: '#ffbf12' }}>creators scale like startups</span>. <br />
          As your <span style={{ color: '#ffbf12' }}>followers grow</span>, <br />
          prices and sales rise, directly increasing your brand’s value, <br />
          just <span style={{ color: '#ffbf12' }}>like startups grow</span> by{' '}
          <span style={{ color: '#ffbf12' }}>expanding</span> their{' '}
          <span style={{ color: '#ffbf12' }}>user base</span>.
          <br />
          <br />
          Achieving a $1B brand can be done <span style={{ color: '#ffbf12' }}>
            collectively
          </span>{' '}
          by partnering <br />
          with other creators to sell cards, merging brand assets, or both.
        </Typography>

        <img
          src={BrandSynergy}
          alt="Brand synergy"
          style={{ display: 'block', margin: '0 auto', width: '41vw', paddingRight: '5%' }}
        />
      </Container>
    </FadeIn>
  );
}
