import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
// import CardHoldersIcon from '../../../../../../images/card-holders-icon.svg';

export default function RewardYourCommunity__EverythingIsPreset(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          <span style={{ color: '#ffbf12' }}>
            Buying Thank You Mention cards offers preset pricing, content, and presentation.
          </span>
          <br />
          <br />
          This setup eliminates the need for back-and-forth negotiations <br />
          between creators, brands, and managers, ensuring <br />a smooth and efficient process.
        </Typography>
      </Container>
    </FadeIn>
  );
}
