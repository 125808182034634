import './style.css';

import UsernameHandle from '../../../../../../../images/username-handle.svg';
import SocialIcon from '../../../../../../../images/social-icon.svg';
import FacebookIcon from '../../../../../../../images/facebook-icon.svg';
import XIcon from '../../../../../../../images/x-icon.svg';
import SnapchatIcon from '../../../../../../../images/snapchat-icon.svg';
import ProfilePicEmpty from '../../../../../../../images/profilr-pic-empty.svg';
import InstagramIcon from '../../../../../../../images/instagram-icon.svg';
import TiktokIcon from '../../../../../../../images/tiktok-icon.svg';
import YoutubeIcon from '../../../../../../../images/youtube-icon.svg';
import TradeMark from '../../../../../../../images/trade-mark.svg';
import Rectangle from '../../../../../../../images/rectangle.svg';
import ArrowAnimate from '../../../../../../../images/arrow-animate.svg';
import ArrowAnimateWhite from '../../../../../../../images/arrow-animate-white.svg';

import PaperworkMobile from '../../../../../../../images/paperwork-mobile.png';

import SocialTo1B from '../../../../../../../images/social-to-1b-mobile.png';

import ArrowAnimate2 from '../../../../../../../images/arrow-animate2.svg';
import ArrowAnimateWhite2 from '../../../../../../../images/arrow-animate-white2.svg';
import People from '../../../../../../../images/people.svg';

import unicorn from '../../../../../../../images/unicorn-line-icon.svg';

import Collectively from '../../../../../../../images/collectively.svg';

import { withWidth } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';

function PersonalBrandAnimate({ width, ...props }) {
  const index = props.index;

  const [scale, setScale] = useState(0);
  // console.log(width);
  const sizes = { xs: 30, sm: 40, md: 40, lg: 45, xl: 48 };
  useEffect(() => {
    setScale(sizes[width]);
  }, [width]);

  const [scaleStep3, setScaleStep3] = useState(0.5);

  const [intervalId, setIntervalId] = useState(null);
  const [unicornRatio, setunicornRatio] = useState({ ratio: 33, worth: '323M' });
  useEffect(() => {
    // personal brand asset scale index + scaleArray:
    let i = 1,
      scaleArray = [0.5, 0.7, 0.9];
    // unicorn graph scale unicornRatio + ratioArray:
    const ratioArray = [
      { ratio: 100, worth: '$1B' },
      { ratio: 33, worth: '$323M' },
      { ratio: 66, worth: '$654M' },
    ];

    if (index === 1 && intervalId === null) {
      const id = setInterval(() => {
        // personal brand asset:
        setScaleStep3(scaleArray[i % 3]);
        i++;
        // unicorn graph:
        setunicornRatio(ratioArray[i % 3]);
      }, 1000);
      setIntervalId(id);
    } else if (index !== 1 && intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [index, intervalId]);

  let index4WidthAsset = 0.68;
  const socialMediaContainerCordinate = [
    {
      position: 'absolute',
      bottom: scale * -0.8,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 4,
    },
    {
      position: 'absolute',
      bottom: scale * 0.2 * scaleStep3,
      left: '68%',
      transform: 'translate(-50%,0)',
      width: scale * 4 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * 0,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 4,
      opacity: 0,
    },

    {
      position: 'absolute',
      bottom: scale * -0.98 * index4WidthAsset,
      left: scale * -6 * index4WidthAsset,
      transform: 'translate(-50%,0)',
      width: scale * 4 * index4WidthAsset,
      opacity: 0,
    },
  ];

  const profilePicEmptyCordinate = [
    {
      position: 'absolute',
      bottom: scale * 0.55,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 3,
    },

    {
      position: 'absolute',
      bottom: scale * 0.55 * scaleStep3,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 3 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * 0.55,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 3,
    },
    {
      position: 'absolute',
      bottom: scale * 0.55 * index4WidthAsset,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 3 * index4WidthAsset,
    },
  ];
  const usernameHandleCordinate = [
    {
      position: 'absolute',
      bottom: scale * 0,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 3,
    },

    {
      position: 'absolute',
      bottom: scale * 0 * scaleStep3,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 3 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * 0,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 3,
    },
    {
      position: 'absolute',
      bottom: scale * 0 * index4WidthAsset,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 3 * index4WidthAsset,
    },
  ];
  const TiktokIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 0.74,
      left: scale * -0.05,
      width: scale * 0.53,
    },

    {
      position: 'absolute',
      bottom: scale * -0.68 * scaleStep3,
      left: scale * 0.06 * scaleStep3,
      width: scale * 0.4 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * -0.68,
      left: scale * 0.06,
      width: scale * 0.4,
    },
    {
      position: 'absolute',
      bottom: scale * -0.68 * index4WidthAsset,
      left: scale * 0.06 * index4WidthAsset,
      width: scale * 0.4 * index4WidthAsset,
    },
  ];
  const YoutubeIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 2.1,
      left: scale * -0.45,
      width: scale * 0.65,
    },

    {
      position: 'absolute',
      bottom: scale * -0.68 * scaleStep3,
      left: scale * 1.27 * scaleStep3,
      width: scale * 0.465 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * -0.68,
      left: scale * 1.27,
      width: scale * 0.465,
    },
    {
      position: 'absolute',
      bottom: scale * -0.68 * index4WidthAsset,
      left: scale * 1.27 * index4WidthAsset,
      width: scale * 0.465 * index4WidthAsset,
    },
  ];
  const SnapchatIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 3.35,
      left: scale * 0.1,
      width: scale * 0.68,
    },

    {
      position: 'absolute',
      bottom: scale * -0.71 * scaleStep3,
      left: scale * 0.59 * scaleStep3,
      width: scale * 0.47 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * -0.71,
      left: scale * 0.59,
      width: scale * 0.47,
    },
    {
      position: 'absolute',
      bottom: scale * -0.71 * index4WidthAsset,
      left: scale * 0.59 * index4WidthAsset,
      width: scale * 0.47 * index4WidthAsset,
    },
  ];
  const instagramIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 3.87,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 0.68,
    },

    {
      position: 'absolute',
      bottom: scale * -0.71 * scaleStep3,
      left: scale * 1.93 * scaleStep3,
      width: scale * 0.45 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * -0.71,
      left: scale * 1.93,
      width: scale * 0.45,
    },
    {
      position: 'absolute',
      bottom: scale * -0.71 * index4WidthAsset,
      left: scale * 1.93 * index4WidthAsset,
      width: scale * 0.45 * index4WidthAsset,
    },
  ];
  const XIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 3.35,
      left: scale * 3.35,
      width: scale * 0.56,
    },

    {
      position: 'absolute',
      bottom: scale * -0.68 * scaleStep3,
      left: scale * 3.058 * scaleStep3,
      width: scale * 0.39 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * -0.68,
      left: scale * 3.058,
      width: scale * 0.39,
    },
    {
      position: 'absolute',
      bottom: scale * -0.68 * index4WidthAsset,
      left: scale * 3.058 * index4WidthAsset,
      width: scale * 0.39 * index4WidthAsset,
    },
  ];
  const FacebookIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 1.92,
      left: scale * 3.83,
      width: scale * 0.35,
    },

    {
      position: 'absolute',
      bottom: scale * -0.7 * scaleStep3,
      left: scale * 2.6 * scaleStep3,
      width: scale * 0.23 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * -0.7,
      left: scale * 2.6,
      width: scale * 0.23,
    },
    {
      position: 'absolute',
      bottom: scale * -0.7 * index4WidthAsset,
      left: scale * 2.6 * index4WidthAsset,
      width: scale * 0.23 * index4WidthAsset,
    },
  ];
  const SocialIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 0.74,
      left: scale * 3.65,
      width: scale * 0.55,
    },

    {
      position: 'absolute',
      bottom: scale * -0.73 * scaleStep3,
      left: scale * 3.64 * scaleStep3,
      width: scale * 0.39 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * -0.73,
      left: scale * 3.64,
      width: scale * 0.39,
    },
    {
      position: 'absolute',
      bottom: scale * -0.73 * index4WidthAsset,
      left: scale * 3.64 * index4WidthAsset,
      width: scale * 0.39 * index4WidthAsset,
    },
  ];
  const ProfileTradeMarkIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 3,
      left: scale * 2.83,
      width: scale * 0,
    },

    {
      position: 'absolute',
      bottom: scale * 3 * scaleStep3,
      left: scale * 2.83 * scaleStep3,
      width: scale * 0.9 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * 3,
      left: scale * 2.83,
      width: scale * 0.9,
    },
    {
      position: 'absolute',
      bottom: scale * 3 * index4WidthAsset,
      left: scale * 2.83 * index4WidthAsset,
      width: scale * 0.9 * index4WidthAsset,
    },
  ];
  const UsernameTradeMarkIconCordinate = [
    {
      position: 'absolute',
      bottom: scale * 0.35,
      left: scale * 3.32,
      width: scale * 0,
    },

    {
      position: 'absolute',
      bottom: scale * 0.35 * scaleStep3,
      left: scale * 3.3 * scaleStep3,
      width: scale * 0.47 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * 0.35,
      left: scale * 3.3,
      width: scale * 0.47,
    },
    {
      position: 'absolute',
      bottom: scale * 0.35 * index4WidthAsset,
      left: scale * 3.3 * index4WidthAsset,
      width: scale * 0.47 * index4WidthAsset,
    },
  ];
  const SquareCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.1,
      left: scale * -0.37,
      opacity: 0,
    },

    {
      position: 'absolute',
      bottom: scale * -1.1 * scaleStep3,
      left: scale * -0.37 * scaleStep3,
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -1.1,
      left: scale * -0.37,
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -1.1 * index4WidthAsset,
      left: scale * -0.37 * index4WidthAsset,
      opacity: 1,
    },
  ];

  const textBenithCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },

    {
      position: 'absolute',
      bottom: scale * -1.6 * scaleStep3,
      left: scale * -0.37 * scaleStep3,
      fontSize: scale * 0.24 * scaleStep3,
      width: 'max-content',
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -1.97 * index4WidthAsset,
      left: '50%',
      transform: 'translate(-50%,0)',
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 1,
    },
  ];

  const CollectivelyCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },

    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.44,
      left: scale * 5,
      textAlign: 'center',
      width: scale * 6.5,
      opacity: 1,
    },
  ];

  const textCollectivelyCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },

    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -2.03,
      left: scale * 8.45,
      fontSize: scale * 0.24,
      textAlign: 'center',
      width: 'max-content',
      opacity: 1,
    },
  ];

  const UnicornCollectivelyCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },

    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.56,
      left: '50%',
      transform: 'translate(-50%,0)',
      textAlign: 'center',
      width: 'max-content',
      opacity: 0,
    },
  ];

  function createArrows1() {
    let elements = [];
    let gap = scale * 0.3;
    for (let i = 0; i < 3; i++) {
      elements.push(
        <img
          key={i}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.24,
            position: 'absolute',
            transform: 'rotate(90deg)',
            right: 0,
            bottom: i * gap * 1.6,
          }}
        />,
      );
    }
    for (let i = 1; i <= 4; i++) {
      elements.push(
        <img
          key={i + 'l'}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.242,
            position: 'absolute',
            right: i * gap * 1.6,
            bottom: 2.12 * gap * 1.6,
          }}
        />,
      );
    }
    elements.push(
      <img
        key={'a'}
        src={ArrowAnimate}
        alt="path"
        style={{
          transform: 'rotate(90%)',
          width: scale * 0.64,
          position: 'absolute',
          right: 4 * gap * 1.97,
          bottom: 2.22 * gap * 1.14,
        }}
      />,
    );
    elements.push(
      <img
        src={People}
        key={'p'}
        style={{
          width: scale * 2.1,
          position: 'absolute',
          right: scale * -1.05,
          bottom: -scale * 1.7,
          transform: 'rotate(90deg)',
        }}
      />,
    );

    return elements;
  }

  function createArrows2() {
    let elements = [];
    let gap = scale * 0.3;
    for (let i = 0; i < 5; i++) {
      elements.push(
        <img
          key={i}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.24,
            position: 'absolute',
            transform: 'rotate(90deg)',
            left: 0,
            bottom: i * gap * 1.6,
          }}
        />,
      );
    }
    for (let i = 1; i <= 2; i++) {
      elements.push(
        <img
          key={i + 'l'}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.242,
            position: 'absolute',
            left: i * gap * 1.6,
            bottom: 2.12 * gap * 3.1,
          }}
        />,
      );
    }
    elements.push(
      <img
        key={'a'}
        src={ArrowAnimate2}
        alt="path"
        style={{
          transform: 'rotate(180%)',
          width: scale * 0.64,
          position: 'absolute',
          left: 4 * gap * 1.2,
          bottom: 2.12 * gap * 2.65,
        }}
      />,
    );

    elements.push(
      <Typography
        className={props.fontStyle}
        key={'$'}
        style={{
          fontSize: scale * 1.4,
          position: 'absolute',
          left: -scale * 0,
          bottom: -scale * 1.8,
          transform: 'rotate(90deg)',
          color: '#909090',
        }}
      >
        $
      </Typography>,
    );

    return elements;
  }

  useEffect(() => {
    if (index === 1) {
      const container = document.querySelector('.arrowContainer1');
      const elements = Array.from(container.children);
      let index = 0;
      const intervalTime = 90;

      setInterval(() => {
        // Remove highlight class from all elements

        // Skip the last two elements
        if (index < elements.length - 2) {
          // Apply highlight class to the current element
          elements[elements.length - 2].src = ArrowAnimate;

          elements[index].classList.add('highlight');
          index++;
        } else {
          if (index < elements.length - 1) {
            elements[index].src = ArrowAnimateWhite;
            index++;
          } else {
            elements.forEach((el) => el.classList.remove('highlight'));
            // Reset index to 0 to repeat the cycle
            index = 0;
          }
        }
      }, intervalTime);
    }
  }, [index]);

  useEffect(() => {
    if (index === 1) {
      const container = document.querySelector('.arrowContainer2');
      const elements = Array.from(container.children);
      let index = 0;
      const intervalTime = 90;

      setInterval(() => {
        // Remove highlight class from all elements

        // Skip the last two elements
        if (index < elements.length - 2) {
          // Apply highlight class to the current element
          elements[elements.length - 2].src = ArrowAnimate2;

          elements[index].classList.add('highlight');
          index++;
        } else {
          if (index < elements.length - 1) {
            elements[index].src = ArrowAnimateWhite2;
            index++;
          } else {
            elements.forEach((el) => el.classList.remove('highlight'));
            // Reset index to 0 to repeat the cycle
            index = 0;
          }
        }
      }, intervalTime);
    }
  }, [index]);

  return (
    <Box
      sx={{
        margin: `0 auto`,
        marginBottom: 6,
        padding: { xs: '', sm: '20px !important' },
        position: 'relative',
        width: scale * 4.84,
        height: { xs: scale * 4.3, sm: scale * 5.3 },
      }}
    >
      <div
        style={{
          width: scale * 4,
          height: scale * 5,
          position: index === 1 ? 'absolute' : '',
          top: index === 1 ? scale * -2.8 : '',
          left: 0,
        }}
      >
        <div style={{ transition: 'all 0.2s ease', ...socialMediaContainerCordinate[index] }}>
          <img
            src={ProfilePicEmpty}
            alt="Profile pic"
            style={{
              opacity: 0.4,
              transition: 'all 0.2s ease',
              ...profilePicEmptyCordinate[index],
            }}
          />
          <img
            src={UsernameHandle}
            alt="Username handle"
            style={{ opacity: 0.4, transition: 'all 0.2s ease', ...usernameHandleCordinate[index] }}
          />
          <img
            src={TiktokIcon}
            alt="Tiktok icon"
            style={{ opacity: 0.4, transition: 'all 0.2s ease', ...TiktokIconCordinate[index] }}
          />
          <img
            src={YoutubeIcon}
            alt="Youtube icon"
            style={{ opacity: 0.4, transition: 'all 0.2s ease', ...YoutubeIconCordinate[index] }}
          />
          <img
            src={SnapchatIcon}
            alt="Snapchat icon"
            style={{ opacity: 0.4, transition: 'all 0.2s ease', ...SnapchatIconCordinate[index] }}
          />
          <img
            src={InstagramIcon}
            alt="Instagram icon"
            style={{ opacity: 0.4, transition: 'all 0.2s ease', ...instagramIconCordinate[index] }}
          />
          <img
            src={XIcon}
            alt="X icon"
            style={{ opacity: 0.4, transition: 'all 0.2s ease', ...XIconCordinate[index] }}
          />
          <img
            src={FacebookIcon}
            alt="Facebook icon"
            style={{ opacity: 0.4, transition: 'all 0.2s ease', ...FacebookIconCordinate[index] }}
          />
          <img
            src={SocialIcon}
            alt="Social media icon"
            style={{ opacity: 0.4, transition: 'all 0.2s ease', ...SocialIconCordinate[index] }}
          />
          <img
            src={TradeMark}
            alt="Trade mark icon"
            style={{ transition: 'all 0.2s ease', ...ProfileTradeMarkIconCordinate[index] }}
          />
          <img
            src={TradeMark}
            alt="Trade mark icon"
            style={{ transition: 'all 0.2s ease', ...UsernameTradeMarkIconCordinate[index] }}
          />
          <div
            style={{
              opacity: 0.4,
              width:
                index !== 1
                  ? index !== 3
                    ? scale * 4.84
                    : scale * 4.84 * index4WidthAsset
                  : scale * 4.84 * scaleStep3,
              height:
                index !== 1
                  ? index !== 3
                    ? scale * 5.3
                    : scale * 5.3 * index4WidthAsset
                  : scale * 5.3 * scaleStep3,
              padding:
                index !== 1
                  ? index !== 3
                    ? scale * 0.15
                    : scale * 0.15 * index4WidthAsset
                  : scale * 0.15 * scaleStep3,
              transition: 'all 0.2s ease',
              border: `${
                index !== 1
                  ? index !== 3
                    ? scale * 0.07
                    : scale * 0.07 * index4WidthAsset
                  : scale * 0.07 * scaleStep3
              }px solid #ffffff66`,
              borderRadius: scale * 0.17,
              ...SquareCordinate[index],
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                transition: 'all 0.2s ease',
                border: `${
                  index !== 1
                    ? index !== 3
                      ? scale * 0.07
                      : scale * 0.07 * index4WidthAsset
                    : scale * 0.07 * scaleStep3
                }px solid #ffffff66`,
              }}
            />
          </div>
          {index !== 1 && (
            <Typography
              className={props.fontStyle}
              style={{ position: 'absolute', color: '#ffffff66', ...textBenithCordinate[index] }}
            >
              {index !== 3 ? 'personal brand startup company' : 'Individually'}
            </Typography>
          )}
        </div>
        {index === 1 && (
          <div
            className="arrowContainer1"
            style={{
              position: 'absolute',
              right: scale * 0.8,
              bottom: '0%',
              width: scale * 0.5 * 15,
              height: scale * 0.5 * 15,
              transform: 'rotate(-90deg)',
            }}
          >
            {createArrows1()}
          </div>
        )}
        {index === 1 && (
          <div
            className="arrowContainer2"
            style={{
              position: 'absolute',
              left: -scale * 0.4 * 8.5,
              bottom: '-70%',
              width: scale * 0.5 * 15,
              height: scale * 0.5 * 15,
              transform: 'rotate(-90deg)',
            }}
          >
            {createArrows2()}
          </div>
        )}
        {index === 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: scale * 26,
              position: 'absolute',
              bottom: scale * 0.85,
              left: scale * -0.6,
              transform: 'translate(-50%,0) rotate(-90deg)',
            }}
          >
            <div
              className={props.fontStyle}
              style={{
                textAlign: 'center',
                width: scale * 1.5,
                color: '#ffbf12',
                fontSize: '14px',
                lineHeight: '14px',
                transform: 'rotate(90deg)',
              }}
            >
              {unicornRatio.worth}{' '}
            </div>
            <div style={{ position: 'relative' }}>
              <div
                className="MyBrandStats__IpoProgressSlider"
                style={{
                  diaplay: 'flex',
                  alignItems: 'center',
                  border: '1px solid #fff',
                  borderRadius: scale * 4,
                  height: '13px',
                  width: scale * 9,
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    background: '#ffbf12',
                    padding: '0 1px',
                    margin: 0,
                    borderRadius: '50px',
                    height: unicornRatio.ratio > 1.15 ? '11.5px' : '9.25px',
                    width: `${unicornRatio.ratio}%`,
                    transition: 'width 0.5s ease-out 0s',
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                width: '55px',
                transform: 'rotate(90deg)',
              }}
            >
              <div
                style={{
                  width: scale * 1.5,
                  display: 'inline-flex',
                  // flexDirection: 'column',
                  alignItems: 'flex-end',
                  marginLeft: '10px',
                }}
              >
                <img src={unicorn} alt="Unicorn" style={{ width: '20px', opacity: 0.6 }} />
                <p
                  className={props.fontStyle}
                  style={{
                    fontSize: '14px',
                    lineHeight: '14px',
                    // position: 'absolute',
                    // left: '50%',
                    // top: '-24px',
                    // transform: 'translate(-50%,0)',
                    margin: '0 10px',
                    color: '#ffbf12',
                  }}
                >
                  {' '}
                  {Math.ceil(unicornRatio.ratio)}%
                </p>{' '}
              </div>
            </div>
          </div>
        )}

        {/* <img
          src={Collectively}
          alt="Collectively"
          style={{ transition: 'all 0.2s ease', ...CollectivelyCordinate[index] }}
        />
        <Typography
          className={props.fontStyle}
          style={{ position: 'absolute', color: '#ffffff66', ...textCollectivelyCordinate[index] }}
        >
          Collectively
        </Typography> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'all 0.2s ease',
            ...UnicornCollectivelyCordinate[index],
          }}
        >
          <img
            src={unicorn}
            alt="Collectively"
            style={{ width: scale * 1.8, marginBottom: scale * 0.2 }}
          />
          <Typography
            className={props.fontStyle}
            style={{
              color: '#ffffff',
              fontSize: scale * 0.4,
            }}
          >
            $1B social media <br />
            Personal brand
          </Typography>
            <img
              src={ArrowAnimate2}
              style={{
                position: 'absolute',
                top: '43%',
                left: scale * -1.3,
                transform: 'translate(0,-50%)',
                width: scale * 0.72,
              }}
            />
          
        </div>

        {index === 2 && (
          <img
            src={PaperworkMobile}
            alt="Paperwork"
            style={{ position: 'absolute', top: '-168%', left: '-25%', width: scale * 6.85 }}
          />
        )}
        {index === 3 && (
          <img
            src={SocialTo1B}
            alt="Social to $1B"
            style={{ position: 'absolute', top: '-140%', left: '7%', width: scale * 3.5 }}
          />
        )}
      </div>
    </Box>
  );
}
export default withWidth()(PersonalBrandAnimate);
