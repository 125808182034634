import React, { useEffect, useState } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import { withWidth } from '@material-ui/core';

const ImageGallery = (props) => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const { images, thumbnail } = props;

  useEffect(() => {
    // when thumbnail not applied by this ImageGallery - the 'open' controled by the parent component
    if (props.open) {
      setCurrentIndex(props.index);
      setOpen(true);
    }
  }, [props.open]);

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    props.open && props.setOpen(false); // when thumbnail not applied by this ImageGallery - the 'open' controled by the parent component
    setOpen(false);
    setZoom(1);
    setPosition({ x: 0, y: 0 });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    setZoom(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    setZoom(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.2);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => (prevZoom > 1 ? prevZoom - 0.2 : prevZoom));
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleWheel = (e) => {
    e.preventDefault();
    if (e.deltaY > 0) {
      handleZoomOut();
    } else {
      handleZoomIn();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {thumbnail && (
        <div
          style={{
            display: 'flex',
            flexDirection: props.flexDirection,
            // flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            // width: '60vw',
            // maxWidth: '800px',
            margin: '0 auto',
            alignItems: 'center',
          }}
        >
          {images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`thumbnail-${index}`}
              style={{
                //  ...props.sizes[index],
                width: props.sizes ? props.sizes[index] : '',
                height: props.flexDirection !== 'column' ? '100%' : `${100 / (images.length + 1)}%`,
                maxHeight: '40vh',
                margin: 5,
                cursor: 'pointer',
              }}
              onClick={() => handleOpen(index)}
            />
          ))}
        </div>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '80vw',
              height: '80vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'black',
              overflow: 'hidden',
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: { xs: 5, sm: 16 },
                right: { xs: 5, sm: 16 },
                color: 'white',
              }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
            <IconButton
              sx={{
                position: 'absolute',
                top: { xs: '95%', sm: '50%' },
                left: 16,
                color: 'white',
                transform: 'translateY(-50%)',
                zIndex: 4,
              }}
              onClick={handlePrev}
            >
              <ArrowBack />
            </IconButton>
            <img
              src={images[currentIndex]}
              alt={`full-${currentIndex}`}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                transition: dragging ? 'none' : 'transform 0.2s',
                cursor: dragging ? 'grabbing' : 'grab',
              }}
              onWheel={handleWheel}
            />
            <IconButton
              sx={{
                position: 'absolute',
                top: { xs: '95%', sm: '50%' },
                right: 16,
                color: 'white',
                transform: 'translateY(-50%)',
              }}
              onClick={handleNext}
            >
              <ArrowForward />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default withWidth()(ImageGallery);
