import { Box, Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import DigitalCard from '../../../../../../../images/digital-card.png';
import DigitalCardDetails from '../../../../../../../images/digital-card-details.png';
import EngagementWithDigitalCard from '../../../../../../../images/engagement-with-digital-card.png';
import DoubleArrows from '../../../../../../../images/double-arrow-down-white.png';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

export default function OneProduct__TYMentionDC(props) {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  return (
    <FadeIn>
      <Box>
        <SwipeableViews
          //   className={}
          index={index}
          onChangeIndex={handleChangeIndex}
        >
          <div>
            <Typography
              className={props.fontStyle}
              variant="subtitle1"
              sx={{
                position: 'relative',
                display: 'block',
                textAlign: 'center',
                color: '#ffffff44',
                lineHeight: 1.3,
                marginTop: 2,
              }}
            >
              On PassionStocks,{' '}
              <span style={{ color: '#ffbf12' }}>
                creators sell only one product: a thank-you mention card.
              </span>
              <br />
              <br />
              <span style={{ color: '#ffbf12' }}>
                Buyers get a one-time Instagram story mention per card
              </span>
              <br />
              and, if they hold the card,
              <br />
              an annual end-of-year mention as a courtesy.
            </Typography>
            <br />
            <br />

            <Typography
              className={props.fontStyle}
              variant="subtitle2"
              sx={{ color: '#ffffff66', textAlign: 'center' }}
            >
              This is a thank-you mention digital card (marketplace view)
            </Typography>
            <div
              style={{
                margin: '0 auto',

                maxWidth: '80vw',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ width: '20vw' }} />
              <img
                src={DigitalCard}
                alt="Thank You Mention Digital Card"
                style={{ width: '40vw', marginTop: 26 }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '20vw',
                  opacity: 0.44,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIndex(1);
                }}
              >
                <img
                  src={DoubleArrows}
                  alt="Click to view card details"
                  style={{ width: '2.5vw', transform: 'rotate(-90deg)', marginTop: 26 }}
                />
                <Typography
                  className={'headline'}
                  sx={{ textAlign: 'center', lineHeight: 1.2, marginTop: 2 }}
                >
                  view card <br />
                  details
                </Typography>
              </div>
            </div>
          </div>

          <div
            style={{
              margin: '30px auto 0',
              maxWidth: '90vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={DoubleArrows}
              alt="Click to view card details"
              style={{
                position: 'relative',
                alignItems: 'center',
                width: '6.5vw',
                transform: 'rotate(90deg)',
                opacity: 0.44,
                cursor: 'pointer',
                paddingRight: '3.5vw',
              }}
              onClick={() => {
                setIndex(0);
              }}
            />
            <div>
              <Typography
                className={props.fontStyle}
                variant="subtitle2"
                sx={{ color: '#ffffff66', textAlign: 'center' }}
              >
                This is a thank-you mention digital card (marketplace view)
              </Typography>
              <img
                src={DigitalCardDetails}
                alt="Thank You Mention Digital Card"
                style={{ position: 'relative', width: '50vw', zIndex: 6 }}
              />{' '}
              <Typography
                className={props.fontStyle}
                variant="subtitle2"
                sx={{ color: '#ffffff66', textAlign: 'center', marginTop: 5, marginBottom: 2 }}
              >
                Engagement Methods with thank-you mention digital card (marketplace view)
              </Typography>
              <img
                src={EngagementWithDigitalCard}
                alt="Engagement Methods with thank-you mention digital card"
                style={{
                  display: 'block',
                  width: '44.5vw',
                  margin: '0 auto',
                  paddingLeft: '0.95vw',
                }}
              />
            </div>
            <div style={{ width: '6.5vw', paddingRight: '3.5vw' }} />
          </div>
        </SwipeableViews>
      </Box>
    </FadeIn>
  );
}
