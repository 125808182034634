import { Box, Typography } from '@mui/material';
import RealTime from '../../../../../../../images/real-time.svg';

export default function RewardYourCommunity__RealTimeMarketing(props) {
  return (
    <Box>
      <Typography
        className={props.fontStyle}
        sx={{
          position: 'relative',
          display: 'block',
          textAlign: 'center',
          color: '#ffffff44',
          lineHeight: 1.2,
          marginTop: 12,
          marginBottom: 3,
          fontSize: '11px',
        }}
      >
        Brands can boost
        <br />
        <span style={{ color: '#ffbf12' }}>
          real-time marketing with
          <br /> Thank You Mention cards
        </span>
        . <br /> <br />
        When a <span style={{ color: '#ffbf12' }}>creator goes viral</span>, <br />
        <span style={{ color: '#ffbf12' }}>brands can instantly</span>
        <br /> buy these cards to <span style={{ color: '#ffbf12' }}>be featured</span>
        <br /> in the <span style={{ color: '#ffbf12' }}>creator’s stories</span>.
        <br /> <br />
        This quick,
        <br /> automated solution helps brands
        <br />
        <span style={{ color: '#ffbf12' }}>capitalize on the viral wave</span>, <br />
        ensuring timely and
        <br /> effective exposure.{' '}
      </Typography>
      <img
        src={RealTime}
        alt="Real time icon"
        style={{ display: 'block', margin: '0 auto', width: '13vw', maxWidth: '100px' }}
      />
    </Box>
  );
}
