import { Box, Typography } from '@mui/material';
import DigitalCardDetails from '../../../../../../../images/digital-card-details.png';
import EngagementDigitalCard from '../../../../../../../images/engagement-with-digital-card.png';
import DoubleArrows from '../../../../../../../images/double-arrow-down-white.png';

export default function OneProduct__TYMentionDC__s2(props) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginTop: 5,
      }}
    >
      <Typography
        className={props.fontStyle}
        sx={{
          color: '#ffffff66',
          textAlign: 'center',
          fontSize: '12px',
          lineHeight: 1.2,
          marginBottom: 1,
        }}
      >
        This is a thank-you mention
        <br /> digital card (marketplace view)
      </Typography>
      <img
        src={DigitalCardDetails}
        alt="Thank You Mention Digital Card Details"
        style={{ width: '95vw', paddingBottom: '10px' }}
      />
      <Typography
        className={props.fontStyle}
        sx={{
          color: '#ffffff66',
          textAlign: 'center',
          fontSize: '12px',
          lineHeight: 1.2,
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        Engagement Methods with
        <br /> thank-you mention digital card
        <br /> (marketplace view)
      </Typography>
      <img
        src={EngagementDigitalCard}
        alt="Engagement Methods with thank-you mention digital card"
        style={{ width: '95vw', paddingBottom: '10px' }}
      />

      <img
        src={DoubleArrows}
        alt="Click to view card details"
        style={{
        
          width: '6vw',
          transform: 'rotate(90deg)',
          opacity: 0.44,
          cursor: 'pointer',
          margin:'12px auto 0'
        }}
        onClick={() => {
          props.setSwipIndex(0);
        }}
      />
    </Box>
  );
}
