import { Box, Typography } from '@mui/material';
import ProcessSteps from '../../../../components/ProcessSteps';
import PersonalBrandAnimate from '../Animate/desktop';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
}));

export default function StepsContent__desktop(props) {
  const { index, setIndex, stepsNames, stepDescription, scale } = props;

  const classes = useStyles();

  return (
    <Box>
      <div style={{ margin: '30px auto 0', width: 'fit-content' }}>
        <ProcessSteps
          process
          index={index}
          setIndex={setIndex}
          length={stepsNames.length}
          stepsNames={stepsNames}
        />
      </div>

      <div>
        <div
          style={{
            width: '30%',
            position: 'relative',
            width: 'fit-content',
            margin: index === 2 ? '80px auto 0' : '0 auto',
          }}
        >
          <PersonalBrandAnimate index={index} fontStyle={classes.textMonarcha} />
          <div
            style={{
              position: 'absolute',
              bottom: index === 3 ? '-50px' : '-70px',
              left: index === 2 ? '150%' : index === 3 ? '0' : '-150%',
              width: '100%',
              height: '100%',
            }}
          >
            {stepDescription[index]}
          </div>
          {index === 1 && (
            <div
              style={{
                position: 'absolute',
                bottom: '-70px',
                left: '150%',
                width: '100%',
                height: '100%',
              }}
            >
              <Typography
                className={classes.textMonarcha}
                style={{
                  color: '#909090',
                  width: 'max-content',
                  textAlign: 'center',
                  position: 'absolute',
                  top: '5%',
                  left: '-28%',
                  fontSize: scale * 0.1,
                  lineHeight: 1.2,
                }}
              >
                When you <span style={{ color: '#ffbf12' }}>make money</span>, <br />
                your company’s <span style={{ color: '#ffbf12' }}>stock goes up</span>.{' '}
              </Typography>
            </div>
          )}
          <div
            style={{
              width: '80vw',
              position: 'absolute',
              bottom: index === 0 ? '-40%' : '-80%',
              left: '50%',
              transform: 'translate(-50%,0)',
            }}
          >
            <Typography
              className={classes.textOpenSans}
              sx={{
                color: '#707070',
                fontSize: '12px',
                lineHeight: 1.3,
                fontWeight: 'lighter',
                margin: '0 auto',
                maxWidth: '930px',
              }}
            >
              {/* {index === 0 &&
                `*Mostly consistent means minor variations like dashes, underscores, numbers, or slight
      differences in the picture could be part of your trademark. Always consult with a lawyer
      or use PassionStocks’ third-party services for trademark advice.`} */}
              {index === 2 &&
                `*PassionStocks does not provide legal advice. Always consult with a lawyer or use PassionStocks’ third-parties paperwork services for trademark advice and more.`}
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  );
}
