import { createTheme } from "@mui/material";

const theme = createTheme();

theme.typography.h2 = {
    fontSize: '2.2rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '3.75rem',
    },
};theme.typography.h3 = {
    fontSize: '2rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '3rem',
    },
};
theme.typography.h4 = {
    fontSize: '1.4rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '2.125rem',
    },
};
theme.typography.h5= {
    fontSize: '1.15rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
    },
};
theme.typography.h6= {
    fontSize: '0.95rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
    },
};
export default theme;