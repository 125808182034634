import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import RealTime from '../../../../../../../images/real-time.svg';

export default function RewardYourCommunity__RealTimeMarketing(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          Brands can boost{' '}
          <span style={{ color: '#ffbf12' }}>real-time marketing with Thank You Mention cards</span>
          . <br /> <br />
          When a <span style={{ color: '#ffbf12' }}>creator goes viral</span>, <br />
          <span style={{ color: '#ffbf12' }}>brands can instantly</span> buy these cards to{' '}
          <span style={{ color: '#ffbf12' }}>be featured</span> in the{' '}
          <span style={{ color: '#ffbf12' }}>creator’s stories</span>.
          <br /> <br />
          This quick, automated solution helps brands{' '}
          <span style={{ color: '#ffbf12' }}>capitalize on the viral wave</span>, <br />
          ensuring timely and effective exposure.{' '}
        </Typography>
        <img
          src={RealTime}
          alt="Real time icon"
          style={{ display: 'block', margin: '0 auto', width: '13vw', maxWidth: '100px' }}
        />
      </Container>
    </FadeIn>
  );
}
