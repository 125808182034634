import { useState } from 'react';
import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProcessSteps from '../../../../components/ProcessSteps';
import RewardYourCommunity__FullyFeaturedStory from './RewardYourCommunity__FullyFeaturedStory';
import RewardYourCommunity__RealTimeMarketing from './RewardYourCommunity__RealTimeMarketing';
import RewardYourCommunity__EverythingIsPreset from './RewardYourCommunity__EverythingIsPreset';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%,0)',
    width: 'max-content',
    textAlign: 'center',
    fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

export default function RewardYourCommunity__desktop(props) {
  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const stepsNames = [
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{ color: index === 0 ? 'white' : '909090' }}
    >
      Fully featured <br />
      story
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{ color: index === 1 ? 'white' : '909090' }}
    >
      Real-time <br />
      marketing
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{ color: index === 2 ? 'white' : '909090' }}
    >
      Everything <br />
      is preset
    </Typography>,
  ];

  return (
    <Container sx={{ width: '100%', margin: '0 auto' }}>
      <div style={{ width: 'fit-content', margin: '20px auto 0' }}>
        <ProcessSteps
          index={index}
          setIndex={setIndex}
          length={stepsNames.length}
          stepsNames={stepsNames}
        />
      </div>
      <Container sx={{ marginTop: 12 }}>
        {index === 0 && (
          <RewardYourCommunity__FullyFeaturedStory fontStyle={classes.textMonarcha} />
        )}
        {index === 1 && <RewardYourCommunity__RealTimeMarketing fontStyle={classes.textMonarcha} />}
        {index === 2 && (
          <RewardYourCommunity__EverythingIsPreset fontStyle={classes.textMonarcha} />
        )}
      </Container>
    </Container>
  );
}
