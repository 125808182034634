import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import TYMentionEx1 from '../../../../../../../images/ty-mention-ex1.png';
import TYMentionEx2 from '../../../../../../../images/ty-mention-ex2.png';
import TYMentionEx3 from '../../../../../../../images/ty-mention-ex3.png';
import ImageGallery from '../../../../../components/ImageGallery';

export default function OneProduct__AutomatedFormat(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          The <span style={{ color: '#ffbf12' }}>buyer</span> gets a{' '}
          <span style={{ color: '#ffbf12' }}>mention</span> with{' '}
          <span style={{ color: '#ffbf12' }}>just</span> their{' '}
          <span style={{ color: '#ffbf12' }}>Instagram handle</span>.
          <br />
          It’s a <span style={{ color: '#ffbf12' }}>fixed template</span> with no option to add text
          or images, <br />
          and the <span style={{ color: '#ffbf12' }}>delivery is fully automated</span>. <br />
          see examples below.
        </Typography>
        {/* <img
          src={TYMentionExamples}
          alt="Thank You Mention Examples"
          style={{display:'block',margin:'0 auto', width: '50vw', paddingRight: '5%' }}
        /> */}
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '90vw',
            margin: '0 auto',
          }}
        >
          <img
            src={TYMentionEx1}
            alt="Thank You Mention Example"
            style={{ display: 'block', margin: '0 auto', width: '15vw', paddingRight: '5%' }}
          />
          <img
            src={TYMentionEx2}
            alt="Thank You Mention Example"
            style={{ display: 'block', margin: '0 auto', width: '15vw', paddingRight: '5%' }}
          />
          <img
            src={TYMentionEx3}
            alt="Thank You Mention Example"
            style={{ display: 'block', margin: '0 auto', width: '15vw', paddingRight: '5%' }}
          />
        </div> */}
        {/* <div style={{width:'90vw',margin:'0 auto'}}> */}
          <ImageGallery images={[TYMentionEx1, TYMentionEx2, TYMentionEx3]} thumbnail/>
        {/* </div>{' '} */}
      </Container>
    </FadeIn>
  );
}
