import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import TotalStatsExampls from '../../../../../../../images/total-stats-example.svg';
import Marketplace from '../../../../../../../images/marketplace.svg';

export default function OneProduct__PricingMethod(props) {
  return (
    <FadeIn>
      <Container >
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          On PassionStocks, the <span style={{ color: '#ffbf12' }}>pricing method</span> of
          thank-you mention cards <br />
          is <span style={{ color: '#ffbf12' }}>like buying commercial ad slots</span>, <br />
          such as those during the World Cup, <br />
          because creators can only sell a <br />
          <span style={{ color: '#ffbf12' }}>limited number of story mention cards</span>.
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '0 auto' }}>
          <img src={TotalStatsExampls} alt="Figure available supply" style={{ width: '20vw' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '60vw',
            }}
          >
            <Typography
              className={props.fontStyle}
              variant="subtitle1"
              sx={{
                color: '#ffffff44',
                lineHeight: 1.3,
                textAlign: 'center',
                marginTop: 2,
                marginBottom: 4,
              }}
            >
              Story mention <span style={{ color: '#ffbf12' }}>card prices</span> <br />
              can be set at a{' '}
              <span style={{ color: '#ffbf12' }}>
                fixed price by the creator <br />
                or
              </span>{' '}
              follows the <span style={{ color: '#ffbf12' }}>market price</span> <br />
              which fluctuates.
              <br />
              <br />
              the <span style={{ color: '#ffbf12' }}>market price</span> is generated by{' '}
              <span style={{ color: '#ffbf12' }}>Our algorithm</span>,<br />
              setting a <span style={{ color: '#ffbf12' }}>fair price per story mention </span>
              <br />
              based on <span style={{ color: '#ffbf12' }}>social media & market trends</span>.
            </Typography>
            <img src={Marketplace} alt="Marketplace icon" style={{ width: '5vw' }} />
          </div>
        </div>
      </Container>
    </FadeIn>
  );
}
