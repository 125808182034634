import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import TYMentionEx from '../../../../../../../images/ty-mention-ex3.png';

export default function RewardYourCommunity__FullyFeaturedStory(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          By buying <span style={{ color: '#ffbf12' }}>60 Thank-You Mention cards</span>, a business
          can be <span style={{ color: '#ffbf12' }}>fully featured on your story</span>,
          <br />
          gaining exclusive visibility for their brand.
        </Typography>
        <img
          src={TYMentionEx}
          alt="Thank you mention example"
          style={{ display: 'block', margin: '0 auto', width: '30vw', maxWidth: '350px' }}
        />
      </Container>
    </FadeIn>
  );
}
