import { Box, Typography } from '@mui/material';

import RewardYourCommunityIcon from '../../../../images/reward-your-community-icon.svg';
import { makeStyles } from '@mui/styles';
import { withWidth } from '@material-ui/core';
import RewardYourCommunity__desktop from './components/desktop';
import RewardYourCommunity__mobile from './components/mobile';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%,0)',
    width: 'max-content',
    textAlign: 'center',
    fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

const RewardYourCommunity = ({ width, ...props }) => {
  const classes = useStyles();

  return (
    <Box sx={{ marginBottom: 15 }}>
      <Typography
        className={classes.textMonarcha}
        variant="h5"
        sx={{
          textAlign: 'center',
          lineHeight: 1.3,
          color: '#ffffff77',
          marginBottom: { xs: 1, sm: 0 },
        }}
      >
        Reward Your Community with{width==='xs'&&<br/>} Every brand Partnership
      </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{ textAlign: 'center', lineHeight: 1.3, color: '#ffffff77' }}
      >
        share your success of every business deal{width==='xs'&&<br/>} by giving perks to your cardholders.{' '}
      </Typography>
      <img
        src={RewardYourCommunityIcon}
        alt="Rewards your community icon"
        style={{
          display: 'block',
          margin: width === 'xs' ? '20px auto 0' : '20px auto 30px',
          width: width === 'xs' ? '12vw' : '5vw',
        }}
      />

{width === 'xs' ? <RewardYourCommunity__mobile /> : <RewardYourCommunity__desktop />}

    </Box>
  );
};

export default withWidth()(RewardYourCommunity);
