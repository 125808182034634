import { useEffect, useState } from 'react';
import ProcessSteps__mobile from './mobile';
import ProcessSteps__desktop from './desktop';
import { withWidth } from '@material-ui/core';

const ProcessSteps = ({ width, ...props }) => {
  const [scale, setScale] = useState(0);
  // console.log(width);
  const sizes = { xs: 95, sm: 160, md: 160, lg: 160, xl: 200 };
  useEffect(() => {
    setScale(sizes[width] * 1.2);
  }, [width]);

  return width === 'xs' ? (
    <ProcessSteps__mobile {...props} scale={scale} />
  ) : (
    <ProcessSteps__desktop {...props} scale={scale} />
  );
};

export default withWidth()(ProcessSteps);
