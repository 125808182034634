import { Box, Typography } from '@mui/material';
import Agency__OpeningSlide from './OpeningSlide';
import { makeStyles } from '@mui/styles';
import Agency__EstimateSlide from './Estimate';
import Agency__CompanyProcess from './AgencyCompanyProcess';
import Agency__Copy1 from './Copy1';

const useStyles = makeStyles(() => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));
export default function AgencyPage(props) {
  const classes = useStyles();

  return (
    <Box sx={{ position: 'relative', width: '100vw', paddingBottom: 9 }}>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        onClick={() => props.setIndex(0)}
        sx={{ position: 'absolute', top: 0, right: 0, padding: 4, cursor: 'pointer' }}
      >
        Creators
      </Typography>
      <Agency__OpeningSlide isPhone={props.isPhone} />
      <Agency__EstimateSlide isPhone={props.isPhone} />
      <Agency__CompanyProcess />
      <Agency__Copy1 />
    </Box>
  );
}
