import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DoubleArrow from '../../../../images/double-arrow-down-white.png';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stationName: {
    position: 'absolute',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  thumb: {
    cursor: 'pointer',
    borderRadius: '100px',
    '&:hover': {
      boxShadow: '0 0 0 6px #ffffff7a',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(255, 191, 18, 0.5)',
    },
  },
});

export default function ProcessSteps__desktop(props) {
  const classes = useStyles();
  const scale = props.scale;
  const thumbSize = scale * (props.length - 1);
  const lineWidth = scale * (props.length > 3 ? props.length - 1 : 2.5);
  const multipule = lineWidth / (props.length - 1);

  function getThumbs() {
    let thumbs = [];
    for (let i = 0; i < props.length; i++) {
      thumbs.push(
        <svg
          key={i}
          className={classes.thumb}
          height={thumbSize / (props.length * 4.465)}
          width={thumbSize / (props.length * 4.465)}
          style={{
            boxShadow: props.index === i ? '0 0 0 6px #ffbf127a' : '',
            position: 'absolute',
            top: '50%',
            transform: 'translate(0,-50%)',
            left: multipule * i,
          }}
          onClick={() => props.setIndex(i)}
        >
          <circle
            cx="50%"
            cy="50%"
            r="30%"
            stroke={props.index === i ? '#ffbf12' : '#4d4949'}
            strokeWidth="25"
            fill={props.index === i ? '#ffbf12' : '#4d4949'}
          />
        </svg>,
      );
    }
    return thumbs;
  }

  function getStepNames() {
    let stepsNames = [];
    for (let i = 0; i < props.length; i++) {
      stepsNames.push(
        <div style={{ position: 'absolute', width: '5.6%', bottom: '-90%', left: multipule * i }}>
          {props.stepsNames[i]}
        </div>,
      );
    }
    return stepsNames;
  }
  return (
    <div style={{ position: 'relative' }}>
      {' '}
      <Box
        sx={{
          position: 'relative',
          bottom: '0',
          right: '0',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <div
          style={{
            border: '1px solid #d2d2d2',
            height: scale * 0.095,
            width: lineWidth,
            borderRadius: '100px',
            margin: `0 ${scale * 0.07 + 'px'}`,
          }}
        />
        {props.process && (
          <img
            src={DoubleArrow}
            alt="Direction arrow"
            style={{
              position: 'absolute',
              top: scale * 0.0115,
              left: multipule * props.index + multipule / 1.7,
              transform: 'rotate(-90deg) translate(0%,-50%)',
              width: props.index !== props.length - 1 ? scale * 0.065 : 0,
              transition: 'all 0.2s ease-in',
            }}
          />
        )}
        {getThumbs()}
        {getStepNames()}
      </Box>
    </div>
  );
}
