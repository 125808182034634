import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import OurMarketplaceEx from '../../../../images/our-marketplace-ex.png';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
}));

export default function OurMarketplace() {
  const classes = useStyles();

  return (
    <Box sx={{marginTop:6}}>
      <Typography className={classes.textMonarcha} variant="h6" sx={{display:'block',textAlign:'center', margin: '0 auto',marginBottom:5,color:'#ffffff99' }}>
        This is our one-product marketplace
      </Typography>
      <img
        src={OurMarketplaceEx}
        alt="Our one-product marketplace image"
        style={{ display: 'block', margin: '0 auto', width: '60vw',maxWidth:'800px' }}
      />
    </Box>
  );
}
