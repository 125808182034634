import { Box, Typography } from '@mui/material';

export default function CommunityImpact__EarningFromGrowth(props) {
  return (
    <Box>
      <Typography
        className={props.fontStyle}
        sx={{
          position: 'relative',
          display: 'block',
          textAlign: 'center',
          color: '#ffffff44',
          lineHeight: 1.2,
          marginTop: 9,
          fontSize: '11px',
        }}
      >
        <span style={{ color: '#ffbf12' }}>
          Cardholders benefit from
          <br /> your increasing popularity <br />
          exactly as you do. <br /> <br />
          As your fame grows,
          <br /> you can charge more
          <br /> for your products,
        </span>{' '}
        <br />
        and{' '}
        <span style={{ color: '#ffbf12' }}>
          cardholders can resell
          <br /> their Thank You Mention cards
          <br /> at higher prices
        </span>
        .
        <br /> <br />
        Because the{' '}
        <span style={{ color: '#ffbf12' }}>
          cards are
          <br /> re-consumable
        </span>
        , <br />
        the{' '}
        <span style={{ color: '#ffbf12' }}>
          new buyer receives the same
          <br /> one-time story mention
        </span>
        , <br />
        maintaining the card’s
        <br /> full value.
        <br /> <br />
        If cardholders choose to
        <br /> hold onto their cards, <br />
        they will receive an annual
        <br /> end-of-year thank-you mention
        <br /> as a courtesy.
        <br /> <br />
        This partnership allows <br />
        your community to profit
        <br /> from your success, <br />
        <span style={{ color: '#ffbf12' }}>
          turning followers into
          <br /> engaged business partners
          <br /> who grow with you.
        </span>
      </Typography>
    </Box>
  );
}
