import { Box, Typography } from '@mui/material';
import TYMentionEx from '../../../../../../../images/ty-mention-ex3.png';

export default function RewardYourCommunity__FullyFeaturedStory(props) {
  return (
    <Box>
      <Typography
        className={props.fontStyle}
        sx={{
          position: 'relative',
          display: 'block',
          textAlign: 'center',
          color: '#ffffff44',
          lineHeight: 1.2,
          marginTop: 12,
          marginBottom: 3,
          fontSize: '11px',
        }}
      >
        By buying <br />
        <span style={{ color: '#ffbf12' }}>60 Thank-You Mention cards</span>,<br /> a business can
        be <br />
        <span style={{ color: '#ffbf12' }}>fully featured on your story</span>,
        <br />
        gaining exclusive visibility for
        <br /> their brand.
      </Typography>
      <img
        src={TYMentionEx}
        alt="Thank you mention example"
        style={{ display: 'block', margin: '0 auto', width: '30vw', maxWidth: '350px' }}
      />
    </Box>
  );
}
