import { useEffect, useState } from 'react';
import StepsContent__mobile from './mobile';
import StepsContent__desktop from './desktop';
import { withWidth } from '@material-ui/core';

const StepsContent = ({ width, ...props }) => {
  return width === 'xs' ? (
    <StepsContent__mobile {...props} />
  ) : (
    <StepsContent__desktop {...props} />
  );
};

export default withWidth()(StepsContent);
