import { useEffect, useState } from 'react';
import StepsContent__mobile from './mobile';
import StepsContent__desktop from './desktop';
import { withWidth } from '@material-ui/core';

const StepsContent = ({ width, ...props }) => {
  //   const [scale, setScale] = useState(0);
  //   // console.log(width);
  //   const sizes = { xs: 65, sm: 160, md: 160, lg: 160, xl: 200 };
  //   useEffect(() => {
  //     setScale(sizes[width] * 1.2);
  //   }, [width]);

  return width === 'xs' ? (
    <StepsContent__mobile {...props} />
  ) : (
    <StepsContent__desktop {...props} />
  );
};

export default withWidth()(StepsContent);
