import { Box, Typography } from '@mui/material';
// import CardHoldersIcon from '../../../../../../images/card-holders-icon.svg';

export default function RewardYourCommunity__EverythingIsPreset(props) {
  return (
    <Box>
      <Typography
        className={props.fontStyle}
        sx={{
          position: 'relative',
          display: 'block',
          textAlign: 'center',
          color: '#ffffff44',
          lineHeight: 1.2,
          marginTop: 15,
          fontSize: '11px',
        }}
      >
        <span style={{ color: '#ffbf12' }}>
          Buying Thank You Mention cards
          <br /> offers preset pricing, content,
          <br /> and presentation.
        </span>
        <br />
        <br />
        This setup eliminates the need for
        <br /> back-and-forth negotiations <br />
        between creators, <br />
        brands, and managers,
        <br />
        ensuring a smooth and <br />
        efficient process.
      </Typography>
    </Box>
  );
}
