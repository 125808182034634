import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';

export default function CommunityImpact__ExclusiveShoutOut(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          When supporters buy a <span style={{ color: '#ffbf12' }}>Thank You Mention card</span>,{' '}
          <br />
          they get a <span style={{ color: '#ffbf12' }}>24-hour shout-out</span> on your{' '}
          <span style={{ color: '#ffbf12' }}>Instagram Story</span>, <br />
          <span style={{ color: '#ffbf12' }}>visible</span> to{' '}
          <span style={{ color: '#ffbf12' }}>all your followers</span>. <br />
          <br />
          It’s a powerful way to <br />
          <span style={{ color: '#ffbf12' }}>
            acknowledge them as business partners in your personal brand
          </span>
          .
          <br />
          <br />
          <span style={{ color: '#ffbf12' }}>Cardholders</span> receive an{' '}
          <span style={{ color: '#ffbf12' }}>annual story mention as a courtesy</span>,
          <br />
          symbolizing their ongoing support.
        </Typography>
      </Container>
    </FadeIn>
  );
}
