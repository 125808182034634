import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../layouts/fade-in';
import Scalability1 from '../../../../../../images/scalability1.svg';
import Scalability2 from '../../../../../../images/scalability2.svg';
import { useRef, useState } from 'react';
import { useEffect } from 'react';

export default function LimitedAutomatedTYM__Scalability(props) {
  const textRef = useRef(null);

  const [graphImgHeight, setgraphImgHeight] = useState(0);
  useEffect(() => {
    if (textRef.current) {
      setgraphImgHeight(textRef.current.offsetHeight*0.9);
    }
  }, [textRef]);

  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            position: 'relative',
            width: 'fit-content',
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginRight: 'auto !important',
            marginLeft: 'auto !important',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          The thank-you mention card is scalable because <br />
          it’s <span style={{ color: '#ffbf12' }}>digital</span>,{' '}
          <span style={{ color: '#ffbf12' }}>templated</span>,{' '}
          <span style={{ color: '#ffbf12' }}>automated</span>, and{' '}
          <span style={{ color: '#ffbf12' }}>consumable</span>,
          <br />
          <br />
          allowing for <span style={{ color: '#ffbf12' }}>easy replication & distribution </span>
          <br /> without additional effort.{' '}
          <img
            src={Scalability1}
            alt=""
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100%',
              transform: 'translate(120%,0)',
            }}
          />
        </Typography>
        {/* </div> */}
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          Because the thank-you mention is a{' '}
          <span style={{ color: '#ffbf12' }}>one-time consumable digital service</span>,<br /> it{' '}
          <span style={{ color: '#ffbf12' }}>simplifies</span> transactions by reducing{' '}
          <span style={{ color: '#ffbf12' }}>legal and accounting complexities</span>,<br /> as it
          doesn’t involve physical goods or ongoing obligations.
        </Typography>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={Scalability2} style={{ height: graphImgHeight }} />
            <Typography
              ref={textRef}
              className={props.fontStyle}
              variant="subtitle1"
              sx={{
                color: '#ffffff44',
                lineHeight: 1.9,
                textAlign: 'center',
                margin: 0,
                marginLeft:1
              }}
            >
              By <span style={{ color: '#ffbf12' }}>default</span>, cards are{' '}
              <span style={{ color: '#ffbf12' }}>capped</span> at 1% of your total followers at a
              time, <br />
              with a maximum of 250 million cards to{' '}
              <span style={{ color: '#ffbf12' }}>optimize supply & demand</span>. <br />
              <br />
              <span style={{ color: '#ffbf12' }}>You</span> can{' '}
              <span style={{ color: '#ffbf12' }}>adjust</span> this{' '}
              <span style={{ color: '#ffbf12' }}>limit</span> based on{' '}
              <span style={{ color: '#ffbf12' }}>your sales strategy</span>.
            </Typography>
        </div>
      </Container>
    </FadeIn>
  );
}
