import { Box, Container, Typography } from '@mui/material';

import AvatarsGraphs from '../../../../images/avatars-graphs.svg';
import ProcessSteps from '../../components/ProcessSteps';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import OneProduct__TYMentionDC from './components/desktop/OneProduct__TYMentionDC';
import OneProduct__AutomatedFormat from './components/desktop/OneProduct__AutomatedFormat';
import OneProduct__PricingMethod from './components/desktop/OneProduct__PricingMethod';
import { withWidth } from '@material-ui/core';
import OneProductMarketplaceSteps__desktop from './components/desktop';
import OneProductMarketplaceSteps__mobile from './components/mobile';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
}));

const OneProductMarketplaceSteps = ({ width, ...props }) => {
  const classes = useStyles();

  return (
    <Box sx={{ height: 'fit-content', marginBottom: 0 }}>
      <Typography
        className={classes.textMonarcha}
        variant="h5"
        sx={{
          textAlign: 'center',
          lineHeight: 1.3,
          color: '#ffffff77',
          marginBottom: { xs: 1, sm: 0 },
        }}
      >
        Passionstocks One-product marketplace
      </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{ textAlign: 'center', lineHeight: 1.3, color: '#ffffff77' }}
      >
        where product value comes entirely from the personal brand
      </Typography>
      <img
        src={AvatarsGraphs}
        alt="Graph by avatars"
        style={{ display: 'block', margin: '10px auto', width: width === 'xs' ? `70vw` : '25vw' }}
      />

      {width === 'xs' ? (
        <OneProductMarketplaceSteps__mobile />
      ) : (
        <OneProductMarketplaceSteps__desktop />
      )}
    </Box>
  );
};

export default withWidth()(OneProductMarketplaceSteps);
