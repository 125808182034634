import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy4() {
  const classes = useStyles();

  const [margins, setmargins] = useState(0);
  useEffect(() => {
    const copy1aElement = document.getElementById('Copy4A');
    const calculatedMarginA = (window.innerHeight - copy1aElement.clientHeight) / 2;
    setmargins(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
  }, []);
  return (
    <Container sx={{}}>
      <div id="Copy4A" style={{ margin: `${margins}px auto` }}>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1}}
          variant="h2"
        >
          Unlike <br />
          other platforms
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 5 }}
        >
          that offer just <br />
          monetization for cash,
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 0 }}
        >
          Passionstocks
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.8, marginBottom: 0 }}
        >
          offers{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 0 }}
        >
          Dual monetization{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.8 }}
        >
          Through
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 2 }}
        >
          monetization <br />
          for valuation{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginTop: 1.3, marginBottom: 0 }}
        >
          Boosting your brand’s worth <br />
          like a startup valuation <br />
          with each sale.
        </Typography>
      </div>
    </Container>
  );
}
