import { Box, Typography } from '@mui/material';

export default function CommunityImpact__ExclusiveGiveaways(props) {
  return (
    <Box>
      <Typography
        className={props.fontStyle}
        sx={{
          position: 'relative',
          display: 'block',
          textAlign: 'center',
          color: '#ffffff44',
          lineHeight: 1.2,
          marginTop: 9,
          fontSize: '11px',
        }}
      >
        You can attach an{' '}
        <span style={{ color: '#ffbf12' }}>exclusive giveaway to incentivize</span> your community{' '}
        <br />
        to <span style={{ color: '#ffbf12' }}>buy your Thank You Mention cards</span>. <br />
        <br />
        These giveaways can be anything
        <br /> from special content,
        <br /> merchandise, or<br/> unique experiences,
        <br /> offering added value and<br/> encouraging 
        more engagement<br/> from your supporters.
      </Typography>
    </Box>
  );
}
