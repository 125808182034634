import { Box, Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import TotalStatsExampls from '../../../../../../../images/total-stats-example.svg';
import Marketplace from '../../../../../../../images/marketplace.svg';
import DoubleArrows from '../../../../../../../images/double-arrow-down-white.png';
import { useState } from 'react';
import ImageGallery from '../../../../../components/ImageGallery';
import SwipeableViews from 'react-swipeable-views';

export default function OneProduct__PricingMethod(props) {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (i) => {
    setIndex(i);
    console.log(i);
  };

  // ImageGallery without thumbnails - should maintain 'open' state on the parent component
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ width: '62vw', paddingTop: 6 }}>
      {index === 0 && (
        <Typography
          className={props.fontStyle}
          sx={{
            display: 'block',
            color: '#ffffff44',
            width: 'fit-content',
            lineHeight: 1.2,
            fontSize: '11px !important',
            textAlign: 'center',
            marginBottom: 5,
          }}
        >
          On PassionStocks,
          <br /> the <span style={{ color: '#ffbf12' }}>pricing method</span>
          <br /> of thank-you mention cards <br />
          is{' '}
          <span style={{ color: '#ffbf12' }}>
            like buying
            <br /> commercial ad slots
          </span>
          , <br />
          such as those during <br />
          the World Cup, <br />
          because creators can only sell a <br />
          <span style={{ color: '#ffbf12' }}>
            limited number
            <br /> of story mention cards
          </span>
          .
        </Typography>
      )}

      {/* <img src={TotalStatsExampls} alt="Figure available supply" style={{ width: '20vw' }} /> */}

      {index == 1 && (
        <Typography
          className={props.fontStyle}
          sx={{
            display: 'block',
            color: '#ffffff44',
            width: 'fit-content',
            lineHeight: 1.2,
            fontSize: '11px !important',
            textAlign: 'center',
            marginBottom: 5,
          }}
        >
          Story mention <span style={{ color: '#ffbf12' }}>card prices</span> <br />
          can be set at a{' '}
          <span style={{ color: '#ffbf12' }}>
            fixed price by the creator <br />
            or
          </span>{' '}
          follows the <span style={{ color: '#ffbf12' }}>market price</span> <br />
          which fluctuates.
          <br />
          <br />
          the <span style={{ color: '#ffbf12' }}>market price</span> is generated by{' '}
          <span style={{ color: '#ffbf12' }}>Our algorithm</span>,<br />
          setting a <span style={{ color: '#ffbf12' }}>fair price per story mention </span>
          <br />
          based on <span style={{ color: '#ffbf12' }}>social media & market trends</span>.
        </Typography>
      )}
      {/* <img src={Marketplace} alt="Marketplace icon" style={{ width: '5vw' }} /> */}

      <SwipeableViews style={{ width: '100%' }} index={index} onChangeIndex={handleChangeIndex}>
        <div
          style={{
            // width: `${widthText}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={TotalStatsExampls}
            style={{ width: '48.25vw', display: 'block', margin: '0 auto' }}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        <div
          style={{
            // width: `${widthText}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Marketplace}
            style={{ width: '44vw', display: 'block', margin: '0 auto' }}
            // onClick={() => {
            //   setOpen(true);
            // }}
          />
        </div>
      </SwipeableViews>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '9vw',
          opacity: 0.44,
          cursor: 'pointer',
          margin: '25px auto 0',
        }}
        onClick={() => {
          setIndex((index + 1) % 2);
        }}
      >
        <img
          src={DoubleArrows}
          alt="Click to view card details"
          style={{ width: '18px', transform: 'rotate(-90deg)' }}
        />
        <Typography
          className={props.fontStyle}
          sx={{ fontSize: '12px', textAlign: 'center', lineHeight: 1.2, marginTop: 0.5 }}
        >
          {index + 1}/2
        </Typography>
      </div>
      {/* </div>{' '} */}

      <ImageGallery images={[TotalStatsExampls]} open={open} setOpen={setOpen} index={index} />
    </Box>
  );
}
