import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../layouts/fade-in';
import ImageGallery from '../../../../components/ImageGallery';

import TYMVersatilityEx1 from '../../../../../../images/ty-mention-versatility-ex1.png';
import TYMVersatilityEx2 from '../../../../../../images/ty-mention-versatility-ex2.png';
import TYMVersatilityEx3 from '../../../../../../images/ty-mention-versatility-ex3.png';
import TYMVersatilityEx4 from '../../../../../../images/ty-mention-versatility-ex4.png';

export default function LimitedAutomatedTYM__Versatility(props) {
  return (
    <FadeIn>
      <Container>
        {/* <div style={{ width: '90vw', width: '800px'}}> */}
        <ImageGallery
          thumbnail
          flexDirection={'column'}
          sizes={['85%', '100%', '85%', '85%']}
          images={[TYMVersatilityEx1, TYMVersatilityEx2, TYMVersatilityEx3, TYMVersatilityEx4]}
        />
        {/* </div> */}
      </Container>
    </FadeIn>
  );
}
