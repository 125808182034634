import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';

export default function CommunityImpact__ExclusiveGiveaways(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          You can attach an{' '}
          <span style={{ color: '#ffbf12' }}>exclusive giveaway to incentivize</span> your community{' '}
          <br />
          to <span style={{ color: '#ffbf12' }}>buy your Thank You Mention cards</span>. <br />
          <br />
          These giveaways can be anything from special content, merchandise, <br />
          or unique experiences, offering added value and encouraging <br />
          more engagement from your supporters.
        </Typography>
      </Container>
    </FadeIn>
  );
}
