import React, { useState, useEffect } from 'react';
import { Grid, Fade } from '@mui/material';

const FadeIn = ({ children }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setShowContent(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: 'fit-content' }}>
      {children && (
        <Fade in={showContent} timeout={100}>
          {children}
        </Fade>
      )}
    </Grid>
  );
};

export default FadeIn;
