import { Box, Typography } from '@mui/material';

export default function CommunityImpact__ExclusiveShoutOut(props) {
  return (
    <Box>
      <Typography
        className={props.fontStyle}
        sx={{
          position: 'relative',
          display: 'block',
          textAlign: 'center',
          color: '#ffffff44',
          lineHeight: 1.2,
          marginTop: 15,
          fontSize: '11px',
        }}
      >
        When supporters buy
        <br /> a <span style={{ color: '#ffbf12' }}>Thank You Mention card</span>, <br />
        they get a <span style={{ color: '#ffbf12' }}>24-hour shout-out</span> <br />
        on your <span style={{ color: '#ffbf12' }}>Instagram Story</span>, <br />
        <span style={{ color: '#ffbf12' }}>visible</span> to{' '}
        <span style={{ color: '#ffbf12' }}>all your followers</span>. <br />
        <br />
        It’s a powerful way to <br />
        <span style={{ color: '#ffbf12' }}>
          acknowledge them <br />
          as business partners in your
          <br /> personal brand
        </span>
        .
        <br />
        <br />
        <span style={{ color: '#ffbf12' }}>Cardholders</span> receive
        <br /> an{' '}
        <span style={{ color: '#ffbf12' }}>
          annual story mention
          <br /> as a courtesy
        </span>
        ,
        <br />
        symbolizing their
        <br /> ongoing support.
      </Typography>
    </Box>
  );
}
