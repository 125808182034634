import { Box, Container, Typography } from '@mui/material';
import PSLogoYellow from '../../../../images/ps-icon.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));
export default function Agency__OpeningSlide(props) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 9,
          width: 'min-content',
        }}
      >
        <img src={PSLogoYellow} alt="" style={{ width: '35%', display: 'block' }} />
        <Typography
          variant="h3"
          className={classes.textMonarcha}
          sx={{ textTransform: 'none !important', width: 'fit-content' }}
        >
          PassionStocks
        </Typography>
      </Box>
      <Container>
        <Typography
          variant="h4"
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.2 }}
        >
          Turn creators’ agency{props.isPhone && <br />} to stocks
        </Typography>
        <Typography
          variant="h5"
          className={classes.textMonarcha}
          sx={{ color: '#ffffff44', lineHeight: 1.2 }}
        >
          Creator agencies’ value{props.isPhone && <br />} is born here
          <br />
          and raised on {props.isPhone && <br />}the stock exchange
        </Typography>
      </Container>
    </Box>
  );
}
