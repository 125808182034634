import './style.css';

import AgencyCompany from '../../../../../../../images/agency-company.png';
import GetACut from '../../../../../../../images/get-a-cut.svg';
import ManageCreators from '../../../../../../../images/manage-creators.png';
import Rectangle from '../../../../../../../images/rectangle.svg';
import ArrowAnimate from '../../../../../../../images/arrow-animate.svg';
import ArrowAnimateWhite from '../../../../../../../images/arrow-animate-white.svg';

import ArrowAnimate2 from '../../../../../../../images/arrow-animate2.svg';
import ArrowAnimateWhite2 from '../../../../../../../images/arrow-animate-white2.svg';
import People from '../../../../../../../images/people.svg';

import unicorn from '../../../../../../../images/unicorn-line-icon.svg';

import AgencyCollectively from '../../../../../../../images/agency-collectively-mobile.png';

import { withWidth } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';

function PersonalBrandAnimate({ width, ...props }) {
  const index = props.index;

  const [scale, setScale] = useState(0);
  // console.log(width);
  const sizes = { xs: 30, sm: 40, md: 40, lg: 45, xl: 48 };
  useEffect(() => {
    setScale(sizes[width]);
  }, [width]);

  const [scaleStep3, setScaleStep3] = useState(0.5);

  const [intervalId, setIntervalId] = useState(null);
  const [unicornRatio, setunicornRatio] = useState({ ratio: 33, worth: '323M' });
  useEffect(() => {
    // personal brand asset scale index + scaleArray:
    let i = 1,
      scaleArray = [0.5, 0.7, 0.9];
    // unicorn graph scale unicornRatio + ratioArray:
    const ratioArray = [
      { ratio: 100, worth: '$1B' },
      { ratio: 33, worth: '$323M' },
      { ratio: 66, worth: '$654M' },
    ];

    if (index === 2 && intervalId === null) {
      const id = setInterval(() => {
        // personal brand asset:
        setScaleStep3(scaleArray[i % 3]);
        i++;
        // unicorn graph:
        setunicornRatio(ratioArray[i % 3]);
      }, 1000);
      setIntervalId(id);
    } else if (index !== 2 && intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [index, intervalId]);

  let index4WidthAsset = 0.68;
  const socialMediaContainerCordinate = [
    {
      position: 'absolute',
      bottom: scale * 1.2,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 4.8,
    },
    {
      position: 'absolute',
      bottom: scale * 11,
      left: scale * 1.1,
      width: scale * 2.8,
    },
    {
      position: 'absolute',
      bottom: scale * 1.2 - scale * 2 * scaleStep3,
      left: '68%',
      transform: 'translate(-50%,0%)',
      width: scale * 4 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * 12.65 * index4WidthAsset,
      left: scale * 3.48 * index4WidthAsset,
      transform: 'translate(-50%,0)',
      width: scale * 4.8 * index4WidthAsset,
    },
  ];

  const textBenithCordinate = [
    {
      position: 'absolute',
      bottom: scale * -0.47,
      left: scale * -0.01,
      fontSize: scale * 0.23,
      width: 'max-content',
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -0.67,
      left: scale * 0.22,
      fontSize: scale * 0.23,
      width: 'max-content',
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6 * scaleStep3,
      left: scale * -0.37 * scaleStep3,
      fontSize: scale * 0.24 * scaleStep3,
      width: 'max-content',
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -0.73 * index4WidthAsset,
      left: '50%',
      transform: 'translate(-50%,0)',
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 1,
    },
  ];

  const CollectivelyCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 0,
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 0,
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 0,
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.8,
      left: scale * 0.57,
      textAlign: 'center',
      width: scale * 3.5,
      opacity: 1,
    },
  ];

  function createArrows1() {
    let elements = [];
    let gap = scale * 0.3;
    for (let i = 0; i < 3; i++) {
      elements.push(
        <img
          key={i}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.24,
            position: 'absolute',
            transform: 'rotate(90deg)',
            right: 0,
            bottom: i * gap * 1.6,
          }}
        />,
      );
    }
    for (let i = 1; i <= 4; i++) {
      elements.push(
        <img
          key={i + 'l'}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.242,
            position: 'absolute',
            right: i * gap * 1.6,
            bottom: 2.12 * gap * 1.6,
          }}
        />,
      );
    }
    elements.push(
      <img
        key={'a'}
        src={ArrowAnimate}
        alt="path"
        style={{
          transform: 'rotate(90%)',
          width: scale * 0.64,
          position: 'absolute',
          right: 4 * gap * 1.97,
          bottom: 2.22 * gap * 1.14,
        }}
      />,
    );
    elements.push(
      <img
        src={People}
        key={'p'}
        style={{
          width: scale * 2.1,
          position: 'absolute',
          right: scale * -1.05,
          bottom: -scale * 1.7,
          transform: 'rotate(90deg)',
        }}
      />,
    );

    return elements;
  }

  function createArrows2() {
    let elements = [];
    let gap = scale * 0.3;
    for (let i = 0; i < 5; i++) {
      elements.push(
        <img
          key={i}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.24,
            position: 'absolute',
            transform: 'rotate(90deg)',
            left: 0,
            bottom: i * gap * 1.6,
          }}
        />,
      );
    }
    for (let i = 1; i <= 2; i++) {
      elements.push(
        <img
          key={i + 'l'}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.242,
            position: 'absolute',
            left: i * gap * 1.6,
            bottom: 2.12 * gap * 3.1,
          }}
        />,
      );
    }
    elements.push(
      <img
        key={'a'}
        src={ArrowAnimate2}
        alt="path"
        style={{
          transform: 'rotate(180%)',
          width: scale * 0.64,
          position: 'absolute',
          left: 4 * gap * 1.2,
          bottom: 2.12 * gap * 2.65,
        }}
      />,
    );

    elements.push(
      <Typography
        className={props.fontStyle}
        key={'$'}
        style={{
          fontSize: scale * 1.4,
          position: 'absolute',
          left: -scale * 0,
          bottom: -scale * 1.8,
          transform: 'rotate(90deg)',
          color: '#909090',
        }}
      >
        $
      </Typography>,
    );

    return elements;
  }

  useEffect(() => {
    if (index === 2) {
      const container = document.querySelector('.arrowContainer1');
      const elements = Array.from(container.children);
      let index = 0;
      const intervalTime = 90;

      setInterval(() => {
        // Remove highlight class from all elements

        // Skip the last two elements
        if (index < elements.length - 2) {
          // Apply highlight class to the current element
          elements[elements.length - 2].src = ArrowAnimate;

          elements[index].classList.add('highlight');
          index++;
        } else {
          if (index < elements.length - 1) {
            elements[index].src = ArrowAnimateWhite;
            index++;
          } else {
            elements.forEach((el) => el.classList.remove('highlight'));
            // Reset index to 0 to repeat the cycle
            index = 0;
          }
        }
      }, intervalTime);
    }
  }, [index]);

  useEffect(() => {
    if (index === 2) {
      const container = document.querySelector('.arrowContainer2');
      const elements = Array.from(container.children);
      let index = 0;
      const intervalTime = 90;

      setInterval(() => {
        // Remove highlight class from all elements

        // Skip the last two elements
        if (index < elements.length - 2) {
          // Apply highlight class to the current element
          elements[elements.length - 2].src = ArrowAnimate2;

          elements[index].classList.add('highlight');
          index++;
        } else {
          if (index < elements.length - 1) {
            elements[index].src = ArrowAnimateWhite2;
            index++;
          } else {
            elements.forEach((el) => el.classList.remove('highlight'));
            // Reset index to 0 to repeat the cycle
            index = 0;
          }
        }
      }, intervalTime);
    }
  }, [index]);

  return (
    <Box
      sx={{
        margin: `${scale * 1.25}px auto 0`,
        padding: '20px !important',
        position: 'relative',
        width: scale * 4.84,
        height: scale * 5.3,
      }}
    >
      <div
        style={{
          width: scale * 4,
          height: scale * 5,
          position: index === 2 ? 'absolute' : '',
          top: index === 2 ? scale * -4 : '',
          left: 0,
        }}
      >
        {' '}
        <div style={{ transition: 'all 0.2s ease', ...socialMediaContainerCordinate[index] }}>
          <img src={AgencyCompany} style={{ transition: 'all 0.2s ease', width: '100%' }} />
          {index !== 2 && (
            <Typography
              className={props.fontStyle}
              style={{ position: 'absolute', color: '#ffffff66', ...textBenithCordinate[index] }}
            >
              {index === 1 && (
                <span>
                  Creators’ agency
                  <br />
                  startup company
                </span>
              )}
              {index === 0 && 'Creators’ agency startup company'}
              {index === 3 && 'Individually'}
            </Typography>
          )}
        </div>
        {index === 1 && (
          <img
            src={GetACut}
            alt="Deal Icon"
            style={{
              position: 'absolute',
              bottom: scale * 8.2,
              left: '50%',
              transform: 'translate(-50%,0)',
              width: scale * 1,
            }}
          />
        )}
        {index === 1 && (
          <img
            src={ManageCreators}
            alt="Creators"
            style={{
              position: 'absolute',
              bottom: scale * 3.6,
              left: scale * 0.29,
              width: scale * 4,
            }}
          />
        )}
        {index === 2 && (
          <div
            className="arrowContainer1"
            style={{
              position: 'absolute',
              right: scale * 0.8,
              bottom: '0%',
              width: scale * 0.5 * 15,
              height: scale * 0.5 * 15,
              transform: 'rotate(-90deg)',
            }}
          >
            {createArrows1()}
          </div>
        )}
        {index === 2 && (
          <div
            className="arrowContainer2"
            style={{
              position: 'absolute',
              left: -scale * 0.4 * 8.5,
              bottom: '-70%',
              width: scale * 0.5 * 15,
              height: scale * 0.5 * 15,
              transform: 'rotate(-90deg)',
            }}
          >
            {createArrows2()}
          </div>
        )}
        {index === 2 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: scale * 26,
              position: 'absolute',
              bottom: scale * 0.85,
              left: scale * -0.6,
              transform: 'translate(-50%,0) rotate(-90deg)',
            }}
          >
            <div
              className={props.fontStyle}
              style={{
                textAlign: 'center',
                width: scale * 1.5,
                color: '#ffbf12',
                fontSize: '14px',
                lineHeight: '14px',
                transform: 'rotate(90deg)',
              }}
            >
              {unicornRatio.worth}{' '}
            </div>
            <div style={{ position: 'relative' }}>
              <div
                className="MyBrandStats__IpoProgressSlider"
                style={{
                  diaplay: 'flex',
                  alignItems: 'center',
                  border: '1px solid #fff',
                  borderRadius: scale * 4,
                  height: '13px',
                  width: scale * 9,
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    background: '#ffbf12',
                    padding: '0 1px',
                    margin: 0,
                    borderRadius: '50px',
                    height: unicornRatio.ratio > 1.15 ? '11.5px' : '9.25px',
                    width: `${unicornRatio.ratio}%`,
                    transition: 'width 0.5s ease-out 0s',
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                width: '55px',
                transform: 'rotate(90deg)',
              }}
            >
              <div
                style={{
                  width: scale * 1.5,
                  display: 'inline-flex',
                  // flexDirection: 'column',
                  alignItems: 'flex-end',
                  marginLeft: '10px',
                }}
              >
                <img src={unicorn} alt="Unicorn" style={{ width: '20px', opacity: 0.6 }} />
                <p
                  className={props.fontStyle}
                  style={{
                    fontSize: '14px',
                    lineHeight: '14px',
                    // position: 'absolute',
                    // left: '50%',
                    // top: '-24px',
                    // transform: 'translate(-50%,0)',
                    margin: '0 10px',
                    color: '#ffbf12',
                  }}
                >
                  {' '}
                  {Math.ceil(unicornRatio.ratio)}%
                </p>{' '}
              </div>
            </div>
          </div>
        )}
        <img
          src={AgencyCollectively}
          alt="Collectively"
          style={{ transition: 'all 0.2s ease', ...CollectivelyCordinate[index] }}
        />
       
      </div>
    </Box>
  );
}
export default withWidth()(PersonalBrandAnimate);
